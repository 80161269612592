import React from "react";
import { useSpring, animated } from "@react-spring/web";
import { useInView } from "react-intersection-observer";

const TypeScriptLogo = () => {
  // Set up Intersection Observer
  const [ref, inView] = useInView({ threshold: 0.3 });

  // Set up react-spring for opacity
  const [styles, api] = useSpring(() => ({
    opacity: 0, // Start fully transparent
    config: { tension: 120, friction: 14 },
  }));

  // Trigger the fade-in once the TypeScript component is in view
  React.useEffect(() => {
    if (inView) {
      api.start({ opacity: 1 }); // Animate from 0 to 1
    }
  }, [inView, api]);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div ref={ref}>
        <svg
          id="TypeScript"
          xmlns="http://www.w3.org/2000/svg"
          width="300"
          height="150"
          viewBox="0 0 300 150"
        >
          <animated.g style={styles}>
            <g>
              <rect
                x="19.35"
                y="37.12"
                width="75.76"
                height="75.76"
                rx="5.92"
                ry="5.92"
                fill="#3978bd"
              />
              <g>
                <path
                  d="M88.23,91.84c-.44-1.12-1.08-2.11-1.92-2.98-.83-.87-1.84-1.65-3-2.34-1.17-.69-2.48-1.34-3.95-1.95-1.07-.44-2.04-.87-2.89-1.29-.85-.42-1.58-.84-2.17-1.28-.6-.43-1.06-.89-1.38-1.38s-.49-1.03-.49-1.65c0-.56.14-1.07.43-1.52.29-.45.7-.84,1.23-1.16.53-.32,1.18-.57,1.94-.75.77-.18,1.62-.27,2.56-.27.68,0,1.4.05,2.16.15.76.1,1.52.26,2.29.47.77.21,1.51.48,2.24.8.72.32,1.39.7,2.01,1.12v-7.64c-1.24-.48-2.6-.83-4.08-1.06-1.47-.23-3.17-.34-5.07-.34s-3.78.21-5.52.63c-1.74.42-3.27,1.07-4.59,1.95-1.32.89-2.36,2.01-3.13,3.38-.77,1.37-1.15,3.01-1.15,4.92,0,2.43.7,4.51,2.11,6.23,1.41,1.72,3.54,3.18,6.4,4.37,1.12.46,2.17.91,3.14,1.35.97.44,1.81.9,2.52,1.38s1.27,1,1.67,1.56c.41.56.61,1.2.61,1.92,0,.53-.13,1.02-.38,1.47-.26.45-.64.84-1.16,1.17s-1.17.59-1.94.78c-.78.19-1.68.28-2.72.28-1.77,0-3.53-.31-5.27-.93-1.74-.62-3.35-1.55-4.83-2.8v8.17c1.33.68,2.9,1.19,4.72,1.53s3.73.51,5.74.51,3.82-.19,5.59-.56c1.76-.37,3.31-.99,4.64-1.85,1.33-.86,2.38-1.98,3.16-3.37.78-1.39,1.16-3.1,1.16-5.15,0-1.48-.22-2.78-.66-3.9Z"
                  fill="#fff"
                />
                <polygon
                  points="31.48 76.11 41.94 76.11 41.94 106.07 50.27 106.07 50.27 76.11 60.78 76.11 60.78 69.38 31.48 69.38 31.48 76.11"
                  fill="#fff"
                />
              </g>
            </g>
            <g>
              <path
                d="M190.69,78.76c0-3.37-.8-6-2.4-7.89s-3.87-2.84-6.8-2.84-5.38,1.06-7.35,3.17c-1.97,2.11-2.96,4.84-2.96,8.19,0,3.55.9,6.3,2.69,8.26,1.79,1.96,4.26,2.93,7.41,2.93s5.79-.62,7.74-1.85v-3.93c-1.91,1.37-4.01,2.06-6.29,2.06-2.04,0-3.64-.54-4.8-1.62-1.16-1.08-1.77-2.62-1.83-4.61h14.6v-1.87ZM176.25,77.15c.22-1.66.82-3.01,1.78-4.03.97-1.03,2.14-1.54,3.53-1.54s2.53.49,3.34,1.47h0c.81.98,1.22,2.35,1.23,4.11h-9.88Z"
                fill="#3978bd"
              />
              <path
                d="M159.94,68.03c-3.22,0-5.67,1.43-7.34,4.29h-.08v-3.78h-4.89v31.42h4.89v-13.07h.08c1.49,2.41,3.61,3.62,6.37,3.62,3,0,5.36-1.08,7.09-3.24,1.73-2.16,2.59-5.03,2.59-8.62,0-3.24-.77-5.82-2.3-7.73-1.53-1.92-3.67-2.88-6.41-2.88ZM162.03,84.68c-1.03,1.4-2.46,2.1-4.27,2.1-1.55,0-2.82-.54-3.83-1.63-1-1.09-1.51-2.41-1.51-3.97v-2.66c0-1.84.53-3.37,1.59-4.6,1.06-1.23,2.46-1.84,4.2-1.84,1.64,0,2.95.6,3.91,1.79.96,1.19,1.44,2.84,1.44,4.94,0,2.51-.52,4.46-1.55,5.86Z"
                fill="#3978bd"
              />
              <path
                d="M134.23,84.23c-.13.48-.23.87-.3,1.17l-.08.34h-.11c-.11-.48-.2-.86-.27-1.13l-.11-.43-5.15-15.65h-5.43l8.59,21.49-1.46,3.53c-.9,1.79-2.27,2.69-4.1,2.69-.63,0-1.34-.13-2.11-.38v4.06c.69.18,1.55.27,2.58.27,3.66,0,6.5-2.23,8.53-6.69h0s9.76-24.99,9.76-24.99h-4.94l-5.41,15.69Z"
                fill="#3978bd"
              />
              <polygon
                points="126.84 59.92 104.54 59.92 104.54 64.17 113.18 64.17 113.18 90.08 118.18 90.08 118.18 64.17 126.84 64.17 126.84 59.92"
                fill="#3978bd"
              />
              <path
                d="M245.43,60.36c-.33-.29-.72-.43-1.17-.43-.41,0-.79.15-1.12.44-.34.3-.5.68-.5,1.16s.16.9.49,1.21c.33.32.71.48,1.13.48.46,0,.85-.17,1.18-.5.33-.33.49-.73.49-1.19,0-.49-.17-.88-.5-1.17Z"
                fill="#3978bd"
              />
              <path
                d="M259.79,68.03c-1.72,0-3.3.43-4.73,1.29-1.43.86-2.54,2.08-3.31,3.67h-.08v-4.43h-1.93v31.4h1.93v-13.72s.08,0,.08,0c1.54,2.9,3.92,4.35,7.14,4.35,2.88,0,5.2-1.11,6.96-3.33,1.76-2.22,2.64-5.13,2.64-8.73,0-3.23-.77-5.79-2.3-7.68-1.53-1.88-3.66-2.83-6.39-2.83ZM264.64,85.99c-1.39,1.88-3.19,2.82-5.41,2.82s-4.04-.77-5.41-2.32c-1.37-1.55-2.06-3.39-2.06-5.55v-2.71c0-2.27.69-4.24,2.08-5.92,1.39-1.68,3.34-2.52,5.87-2.52,2.12,0,3.82.79,5.09,2.37,1.27,1.58,1.91,3.71,1.91,6.4,0,3.06-.69,5.53-2.08,7.42Z"
                fill="#3978bd"
              />
              <path
                d="M202.14,74.21c-2.73-1.57-4.51-2.81-5.33-3.72-.82-.91-1.24-2.14-1.24-3.68s.6-2.91,1.81-3.95c1.2-1.04,2.83-1.56,4.89-1.56s3.91.4,5.68,1.2v-2.19c-1.8-.6-3.6-.9-5.41-.9-2.77,0-4.99.74-6.65,2.23-1.66,1.49-2.49,3.29-2.49,5.42,0,1.57.41,2.95,1.22,4.15.82,1.2,2.76,2.69,5.83,4.47l.29.17c2.58,1.5,4.27,2.7,5.06,3.58.82.92,1.24,2.15,1.24,3.69,0,1.75-.61,3.11-1.84,4.09-1.22.97-3.02,1.46-5.38,1.46-2.22,0-4.42-.69-6.59-2.06v2.38c.72.42,1.76.79,3.11,1.11,1.36.32,2.56.48,3.6.48,2.94,0,5.22-.73,6.83-2.19,1.61-1.46,2.42-3.34,2.42-5.65,0-1.61-.47-3.04-1.4-4.28-.94-1.24-2.82-2.66-5.65-4.26Z"
                fill="#3978bd"
              />
              <path
                d="M280.65,70.45v-1.76h-5.64v-6.23c-.31.11-.63.22-.96.31-.33.11-.65.22-.96.34v5.58h-3.84v1.76h3.84v14.43c0,3.8,1.58,5.71,4.74,5.71h0c.88,0,1.82-.24,2.82-.71v-1.8c-.92.52-1.78.78-2.57.78-1.11,0-1.9-.33-2.37-1-.47-.66-.7-1.74-.7-3.22v-14.18h5.64Z"
                fill="#3978bd"
              />
              <rect
                x="243.15"
                y="68.54"
                width="2.03"
                height="21.54"
                fill="#3978bd"
              />
              <path
                d="M234.27,69.4c-1.06.92-1.84,2.2-2.33,3.84h-.08v-4.79h-1.89v21.62h1.89v-10.16c0-3.24.57-5.74,1.72-7.5,1.15-1.76,2.57-2.64,4.26-2.64.87,0,1.62.23,2.27.7v-2.07c-.63-.25-1.34-.38-2.14-.38-1.4,0-2.63.46-3.69,1.37Z"
                fill="#3978bd"
              />
              <path
                d="M214.78,71.3c-2.04,2.18-3.05,4.97-3.05,8.39s.9,5.88,2.71,7.88c1.81,2.01,4.19,3.01,7.15,3.01,2.23,0,4.16-.49,5.77-1.47v-2.02c-1.61,1.15-3.51,1.72-5.68,1.72-2.37,0-4.29-.85-5.74-2.54-1.45-1.69-2.17-3.93-2.17-6.72s.79-5.23,2.37-7.04c1.58-1.81,3.65-2.72,6.19-2.72,1.85,0,3.55.48,5.11,1.43v-2.18c-1.55-.67-3.16-1.01-4.81-1.01-3.19,0-5.81,1.09-7.84,3.27Z"
                fill="#3978bd"
              />
            </g>
          </animated.g>
        </svg>
      </div>
    </div>
  );
};

export default TypeScriptLogo;
